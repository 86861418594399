import dayjs from "dayjs";

export const convertAmount = (amount: any) => {
  if (amount >= 1000000000) {
    return (amount / 1000000000).toFixed(2).replace(/\.00$/, "") + "B";
  } else if (amount >= 1000000) {
    return (amount / 1000000).toFixed(2).replace(/\.00$/, "") + "M";
  } else if (amount >= 1000) {
    return (amount / 1000).toFixed(2).replace(/\.00$/, "") + "K";
  }
  return amount;
};

export const convertToCommaSeparatedString = <T extends string>(
  input: T[]
): string => {
  return input.join(", ");
};

export const createTableHeaders = (headers: {}, options?: any) => {
  let headerCells = [];
  for (const [key, value] of Object.entries(headers)) {
    let header = {
      id: key,
      disablePadding: false,
      label: value,
    };

    // if (key === "status") header = { ...header, ...{ badge: true } };
    if (key === "receipt") header = { ...header, ...{ button: true } };

    headerCells.push(header);
  }

  if (options) {
    if (options.action) {
      headerCells = [
        ...headerCells,
        {
          id: "action",
          disablePadding: false,
          label: "Actions",
          actionButtons: options.actionButtons,
        },
      ];
    }
  }
  return headerCells;
};

export const formatDate = (dateStr: string) => {
  try {
    const dateParts = dateStr.split("/");
    const dateObject = new Date(
      `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
    );

    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(dateObject);
  } catch (err) {
    return "-";
  }
};

export const getFormattedDayJSDate = (dateString: string | undefined) => {
  const date = dayjs(dateString);
  if (date.isValid()) {
    return dayjs(date.format("MM/DD/YYYY"));
  }
};

export const getDateInDashFormat = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return (
    year +
    "-" +
    month.toString().padStart(2, "0") +
    "-" +
    day.toString().padStart(2, "0")
  );
};
