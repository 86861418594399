import * as React from "react";
import { Dayjs } from "dayjs";
import {
  useTheme as useMaterialTheme,
  useColorScheme as useMaterialColorScheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
} from "@mui/material/styles";
import {
  extendTheme as extendJoyTheme,
  useColorScheme,
  CssVarsProvider,
  THEME_ID,
} from "@mui/joy/styles";
import Input, { InputProps } from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import {
  unstable_useDateField as useDateField,
  UseDateFieldProps,
} from "@mui/x-date-pickers/DateField";
import { useClearableField } from "@mui/x-date-pickers/hooks";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers/models";
import { Field } from "formik";
import { FormHelperText } from "@mui/material";

const joyTheme = extendJoyTheme();

interface JoyFieldProps extends InputProps {
  label?: React.ReactNode;
  inputRef?: React.Ref<HTMLInputElement>;
  enableAccessibleFieldDOMStructure?: boolean;
  InputProps?: {
    ref?: React.Ref<any>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
  formControlSx?: InputProps["sx"];
}

type JoyFieldComponent = ((
  props: JoyFieldProps & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element) & { propTypes?: any };

const JoyField = React.forwardRef(
  (props: JoyFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      // Should be ignored
      enableAccessibleFieldDOMStructure,

      disabled,
      id,
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      formControlSx,
      endDecorator,
      startDecorator,
      slotProps,
      inputRef,
      ...other
    } = props;

    return (
      <FormControl
        disabled={disabled}
        id={id}
        sx={[
          ...(Array.isArray(formControlSx) ? formControlSx : [formControlSx]),
        ]}
        ref={ref}
      >
        <FormLabel>{label}</FormLabel>
        <Input
          ref={ref}
          disabled={disabled}
          startDecorator={
            <React.Fragment>
              {startAdornment}
              {startDecorator}
            </React.Fragment>
          }
          endDecorator={
            <React.Fragment>
              {endAdornment}
              {endDecorator}
            </React.Fragment>
          }
          slotProps={{
            ...slotProps,
            root: { ...slotProps?.root, ref: containerRef },
            input: { ...slotProps?.input, ref: inputRef },
          }}
          {...other}
        />
      </FormControl>
    );
  }
) as JoyFieldComponent;

interface JoyDateFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {}

const JoyDateField = React.forwardRef(
  (props: JoyDateFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const { slots, slotProps, ...textFieldProps } = props;

    const fieldResponse = useDateField<Dayjs, false, typeof textFieldProps>({
      ...textFieldProps,
      enableAccessibleFieldDOMStructure: false,
    });

    /* If you don't need a clear button, you can skip the use of this hook */
    const processedFieldProps = useClearableField({
      ...fieldResponse,
      slots,
      slotProps,
    });

    return <JoyField ref={ref} {...processedFieldProps} />;
  }
);

const JoyDatePicker = React.forwardRef(
  (props: DatePickerProps<Dayjs, false>, ref: React.Ref<HTMLDivElement>) => {
    return (
      <DatePicker
        ref={ref}
        {...props}
        slots={{ ...props.slots, field: JoyDateField }}
        slotProps={{
          ...props.slotProps,
          field: {
            ...props.slotProps?.field,
            formControlSx: {
              flexDirection: "row",
            },
          } as any,
        }}
      />
    );
  }
);

/**
 * This component is for syncing the theme mode of this demo with the MUI docs mode.
 * You might not need this component in your project.
 */
function SyncThemeMode({ mode }: { mode: "light" | "dark" }) {
  const { setMode } = useColorScheme();
  const { setMode: setMaterialMode } = useMaterialColorScheme();
  React.useEffect(() => {
    setMode(mode);
    setMaterialMode(mode);
  }, [mode, setMode, setMaterialMode]);
  return null;
}

interface IJoyUIDatePickerField {
  label?: string;
  name?: string;
  className?: string;
  errorText?: any;
  horizontal?: boolean;
  border?: boolean;
  onChange?: (date: any) => void;
  rest?: any;
}

const JoyUIDatePickerField = ({
  name,
  errorText,
  label,
  horizontal = true,
  border = true,
  onChange,
  rest,
}: IJoyUIDatePickerField) => {
  const materialTheme = useMaterialTheme();
  return (
    <div className={`w-full`}>
      <MaterialCssVarsProvider>
        <CssVarsProvider theme={{ [THEME_ID]: joyTheme }}>
          <SyncThemeMode mode={materialTheme.palette.mode} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Field name={name}>
              {({ field, form, meta }: any) => (
                <FormControl>
                  {horizontal ? (
                    <div
                      className={`h-12 px-4 py-2 rounded-md ${
                        border && "border border-slate-300 "
                      } justify-start items-center gap-6 flex`}
                    >
                      <div className="grow shrink basis-0 h-8 justify-start items-center flex">
                        <div className="grow shrink basis-0 text-zinc-900 text-base font-semibold leading-snug">
                          {label}
                        </div>
                      </div>
                      <div className="flex-col justify-start items-start inline-flex">
                        <JoyDatePicker
                          {...field}
                          {...rest}
                          value={field.value || null}
                          onChange={(date) => {
                            if (!onChange) {
                              form.setFieldValue(field.name, date);
                            } else onChange(date);
                          }}
                          className={`${!border && "w-52"}`}
                          slotProps={{
                            field: {
                              clearable: false,
                              format: "DD/MM/YYYY",
                              defaultValue: field.value,
                            },
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {label && <FormLabel>{label}</FormLabel>}
                      <JoyDatePicker
                        {...field}
                        {...rest}
                        value={field.value || null}
                        onChange={(date) => {
                          if (!onChange) {
                            form.setFieldValue(field.name, date);
                          } else onChange(date);
                        }}
                        slotProps={{
                          field: {
                            clearable: false,
                            format: "DD/MM/YYYY",
                            sx: { width: "100%" },
                            defaultValue: field.value,
                          },
                        }}
                      />
                    </>
                  )}
                  {meta.touched && meta.error ? (
                    <FormHelperText>
                      <p className="text-[#C41C1C]">{errorText}</p>
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )}
            </Field>
          </LocalizationProvider>
        </CssVarsProvider>
      </MaterialCssVarsProvider>
    </div>
  );
};

export default JoyUIDatePickerField;
