import { Flip, toast } from "react-toastify";

const notification = (
  notificationMessage: string,
  notificationType: "success" | "error" | "info",
  openTime?: number,
  pauseOnHover?: boolean
) => {
  if (notificationType === "error") {
    toast.error(<p className="text-sm">{notificationMessage}</p>, {
      transition: Flip,
      theme: "light",
      autoClose: openTime || 3000,
      closeOnClick: true,
      pauseOnHover: pauseOnHover || false,
    });
  } else if (notificationType === "success") {
    toast.success(<p className="text-sm">{notificationMessage}</p>, {
      transition: Flip,
      theme: "light",
      autoClose: openTime || 3000,
      closeOnClick: true,
      pauseOnHover: pauseOnHover || false,
    });
  } else if (notificationType === "info") {
    toast.info(<p className="text-sm">{notificationMessage}</p>, {
      transition: Flip,
      theme: "light",
      autoClose: openTime || 3000,
      closeOnClick: true,
      pauseOnHover: pauseOnHover || false,
    });
  }
};

export default notification;
