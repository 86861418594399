import { Divider } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getDispiute, updateDispiute } from "../../../../data/api/disputeAPI";
import { useEffect, useRef, useState } from "react";
import { Dispute } from "../../../../data/models/response/disputeResponse";
import { formatDate } from "../../../../utils/helperFunctions";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextAreaField from "../../../../components/formComponents/fieldComponents/TextArea";
import CButton from "../../../../components/formComponents/CButton";
import { DisputeStatus } from "../../../../data/models/enums/DisputeStatus";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setOpenModal,
} from "../../../../data/store/slices/utilSlice";
import notification from "../../../../utils/toast";
import { setLoadingWithDelay } from "../../../../data/store/thunk/utilThunk";

interface IDisputeDetails {
  id: number;
  refreshApi: any;
}

const DisputeDetails = ({ id, refreshApi }: IDisputeDetails) => {
  const lenderName: any = localStorage.getItem("lenderName");
  const [dispute, setDispute] = useState<Dispute | null>(null);
  const formikRef = useRef<any>(null);
  const dispatch: any = useDispatch();

  useEffect(() => {
    getDisputeById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisputeById = async () => {
    dispatch(setLoading(true));
    try {
      const res: { dispute: Dispute } = await getDispiute({
        lender: lenderName,
        id,
      });

      if (res) {
        setDispute(res.dispute);
      }
      dispatch(setLoadingWithDelay(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingWithDelay(false));
    }
  };

  const handleSubmitButtonClick = async (action: string) => {
    await formikRef.current?.setFieldValue("action", action);
    formikRef.current?.submitForm();
  };

  return (
    <>
      <div className="w-full h-[61px] justify-between items-center gap-[68px] inline-flex">
        <div className="h-[61px] justify-start items-center gap-10 flex">
          <div className="px-4 h-[61px] flex-col justify-start items-start inline-flex">
            <div className=" h-8 pr-6 py-1 bg-white/opacity-0 justify-start items-center inline-flex">
              <div className="grow shrink basis-0 h-[19px] justify-start items-center gap-1 flex">
                <div className="text-zinc-900 text-sm font-semibold leading-[18.62px]">
                  Name
                </div>
              </div>
            </div>
            <div className=" h-7 pt-px pb-[3px] bg-white/opacity-0 flex-col justify-start items-start flex">
              <div className="h-[27px] pr-3 py-1 rounded-2xl justify-start items-center gap-2 inline-flex">
                <div className="opacity-60 text-zinc-900 text-base font-semibold leading-normal">
                  {dispute?.name || "-"}
                </div>
              </div>
            </div>
          </div>
          <Divider orientation="vertical" />
          <div className="px-4 h-[61px] flex-col justify-start items-start inline-flex">
            <div className=" h-8 pr-6 py-1 justify-start items-center inline-flex">
              <div className="grow shrink basis-0 h-[19px] justify-start items-center gap-1 flex">
                <div className="text-zinc-900 text-sm font-semibold leading-[18.62px]">
                  Reason
                </div>
              </div>
            </div>
            <div className=" h-7 pr-6 pt-px pb-[3px] flex-col justify-start items-start flex">
              <div className=" pr-3 py-1 rounded-2xl justify-start items-center gap-2 inline-flex">
                <div className="opacity-60 text-zinc-900 text-base font-semibold leading-normal">
                  {dispute?.reason || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[61px] justify-start items-center gap-10 flex">
          <Divider orientation="vertical" />
          <div className="h-[61px] flex-col justify-start items-start inline-flex">
            <div className="pr-6 py-1 justify-start items-center inline-flex">
              <div className="grow shrink basis-0 h-[19px] justify-start items-center gap-1 flex">
                <div className="text-zinc-900 text-sm font-semibold leading-[18.62px]">
                  Status
                </div>
              </div>
            </div>
            <div className="h-6 px-1.5 bg-sky-600 rounded-3xl justify-center items-center gap-1.5 inline-flex">
              <div className="text-center text-white text-sm font-semibold leading-[18.62px]">
                {dispute?.status || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className="py-4" />
      <div className="flex-col justify-start items-start inline-flex mt-4">
        <div className="justify-center items-center inline-flex">
          <div className="h-20 flex-col justify-end items-start inline-flex">
            <div className="w-[223px] h-20 flex-col justify-start items-start flex">
              <div className="w-[260px] px-4 py-2 justify-start items-center inline-flex">
                <div className="w-[228px] py-1 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-zinc-900 text-sm font-semibold leading-[21px]">
                    Start Date
                  </div>
                  <div className="opacity-60 text-zinc-900 text-base font-semibold leading-normal">
                    <span>{formatDate(dispute?.createdAt!)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col justify-end items-start inline-flex">
            <div className="w-[253px] h-20 flex-col justify-start items-start flex">
              <div className="w-[260px] px-4 py-2 justify-start items-center inline-flex">
                <div className="w-[228px] py-1 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-zinc-900 text-sm font-semibold leading-[18.62px]">
                    Attached Files
                  </div>
                  <div className="justify-start items-start gap-2 inline-flex">
                    <div
                      className={`${
                        dispute?.fileUrl && "px-1"
                      } py-0.5 rounded-md justify-start items-center flex`}
                    >
                      {dispute?.fileUrl ? (
                        <div
                          className="text-sm font-semibold leading-[14px] text-primary hover:cursor-pointer"
                          onClick={() =>
                            window.open(dispute?.fileUrl!, "_blank")
                          }
                        >
                          Open File <OpenInNewIcon className="text-primary" />
                        </div>
                      ) : (
                        "No Attachments"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className="py-2" />
      <div className="mt-4 px-4">
        <h3 className="text-zinc-900 text-sm font-semibold leading-[21px]">
          Description
        </h3>
        <p className="opacity-60 text-zinc-900 text-sm font-semibold leading-[18.62px] mt-2">
          {dispute?.note || "No description added"}
        </p>
      </div>

      <div className="mt-4 px-4">
        <h3 className="text-zinc-900 text-sm font-semibold leading-[21px]">
          Comments
        </h3>
        {dispute?.answers.length! > 0 ? (
          <p className="w-[30rem] opacity-60 text-zinc-900 text-sm font-semibold leading-[18.62px] mt-2">
            {dispute?.answers[0]?.comment || "No comments"}
          </p>
        ) : (
          <Formik
            initialValues={{ note: "", action: "" }}
            validationSchema={Yup.object().shape({
              note: Yup.string().required("Please add a comment."),
            })}
            enableReinitialize={true}
            onSubmit={async ({ note, action }) => {
              try {
                dispatch(setLoading(true));
                const obj = {
                  status:
                    action === "resolve"
                      ? DisputeStatus.RESOLVED
                      : DisputeStatus.REJECTED,

                  comment: note,
                };
                await updateDispiute({ lender: lenderName, id, body: obj });

                notification(
                  `Dispute successfully ${
                    action === "resolve" ? "resolved" : "rejected"
                  }`,
                  "success"
                );

                refreshApi(10, 0);
                dispatch(setLoadingWithDelay(false));
                dispatch(
                  setOpenModal({
                    modalName: "viewDisputeDetail",
                    openModel: false,
                  })
                );
              } catch (error: any) {
                notification(error.message, "error");
                dispatch(setLoadingWithDelay(false));
              }
            }}
            innerRef={formikRef}
          >
            {({ errors }) => (
              <Form id="update-dispute">
                <TextAreaField
                  placeholder="Add comments"
                  name={`note`}
                  errorText={errors.note}
                />
                <input type="hidden" name="action" value="" />
              </Form>
            )}
          </Formik>
        )}
      </div>
      {dispute?.answers.length! === 0 && (
        <>
          <Divider className="py-2" />
          <div className="w-full text-end mt-2">
            <>
              <CButton
                text="Reject"
                type="contained"
                className="bg-[#D32F2F] mr-2"
                onClick={() => handleSubmitButtonClick("reject")}
              />

              <CButton
                text="Resolve"
                type="contained"
                className="bg-[#388E3C]"
                onClick={() => handleSubmitButtonClick("resolve")}
              />
            </>
          </div>
        </>
      )}
    </>
  );
};

export default DisputeDetails;
