import React, { useEffect, useState } from "react";
import CTable from "../../../../components/table/CTable";
import InputField from "../../../../components/formComponents/fieldComponents/InputField";
import CButton from "../../../../components/formComponents/CButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  downloadPaymentReport,
  getPaymentReports,
} from "../../../../data/api/reportsAPI";
import { useDispatch } from "react-redux";
import {
  setLoadingWithDelay,
  setSkeletonWithDelay,
} from "../../../../data/store/thunk/utilThunk";
import notification from "../../../../utils/toast";
import {
  PaymentReportResponse,
  Payments,
} from "../../../../data/models/response/paymentResponse";
import {
  createTableHeaders,
  getDateInDashFormat,
} from "../../../../utils/helperFunctions";
import JoyUIDatePickerField from "../../../../components/formComponents/fieldComponents/JoyUIDatePickerField";
import { Form, Formik } from "formik";
import dayjs from "dayjs";
import { reportDateFilterSchema } from "../../../../data/validations/ValidationScemas";

type Props = {};

const PaymentReport = (props: Props) => {
  const lenderName: any = localStorage.getItem("lenderName");
  const [paymentReports, setPaymentReports] = useState<any>();
  const [headers, setHeaders] = useState<any>([]);
  const [total, setTotal] = useState<number>();
  const [startDate, setStartDate] = useState("01/01/2023");
  const [endDate, setEndDate] = useState(
    getDateInDashFormat(new Date().toString())
  );

  const dispatch: any = useDispatch();

  useEffect(() => {
    getReports(10, 0);
  }, []);

  const getReports = async (size: number, page: number) => {
    try {
      dispatch(setSkeletonWithDelay(true));
      const res: PaymentReportResponse = await getPaymentReports({
        size: size,
        page: page,
        startDate: startDate,
        endDate: endDate,
      });

      if (res) {
        setPaymentReports(res.payments);
        setHeaders(createTableHeaders(res.headers));
        setTotal(res.total);
      }
    } catch (error: any) {
      notification(error.message, "error");
    } finally {
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const downloadReport = async () => {
    try {
      dispatch(setLoadingWithDelay(true));

      const res = await downloadPaymentReport({ startDate, endDate });
      if (res.status === 200) {
        const csvString = res.data;
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${lenderName}-${Date.now()}-payment-report.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error("Failed to fetch the payment report.");
      }
    } catch (error: any) {
      notification(error.message, "error");
    } finally {
      dispatch(setLoadingWithDelay(false));
    }
  };

  return (
    <>
      <div className="px-10 py-8">
        <div className="flex justify-between items-center">
          <Formik
            initialValues={{
              startDate: dayjs(startDate),
              endDate: dayjs(endDate),
            }}
            enableReinitialize={true}
            validationSchema={reportDateFilterSchema}
            onSubmit={async (values: any) => {
              getReports(10, 0);
            }}
          >
            {({ errors }) => (
              <Form>
                <div className="">
                  <div className="flex gap-8">
                    <div className="512px">
                      <JoyUIDatePickerField
                        name="startDate"
                        errorText={errors.startDate}
                        horizontal={false}
                        border={false}
                        onChange={(date) => {
                          setStartDate(getDateInDashFormat(date));
                        }}
                        label="Start Date"
                      />
                    </div>
                    <div className="512px">
                      <JoyUIDatePickerField
                        name="endDate"
                        errorText={errors.endDate}
                        horizontal={false}
                        border={false}
                        onChange={(date) => {
                          setEndDate(getDateInDashFormat(date));
                        }}
                        label="End Date"
                      />
                    </div>

                    <div className="pt-6">
                      <CButton
                        className="leading-normal tracking-wide w-32"
                        text="search"
                        type="contained"
                        submit
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="pt-7">
            <CButton
              className="leading-normal tracking-wide"
              text="Download report"
              type="contained"
              onClick={downloadReport}
            />
          </div>
        </div>
        <div className="mt-8">
          <CTable
            headCells={headers}
            rows={paymentReports}
            totalRows={total}
            onRowClick={() => {}}
            minWidth={750}
            noOfCol={10}
            refreshDataAPI={getReports}
            rowIdentifier={"publicId"}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentReport;
