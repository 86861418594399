import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email."),
  password: Yup.string().required("Please enter your password."),
});

export const editBorrowerSchema = Yup.object().shape({
  title: Yup.string().nullable(),
  firstName: Yup.string().nullable(),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  dob: Yup.string().nullable(),
  email: Yup.string().email("Enter a valid email.").nonNullable(),
  phone: Yup.number(),
  address: Yup.string().nullable(),
  city: Yup.string().nullable(),
  country: Yup.string().nullable(),
  zipcode: Yup.string().nullable(),
});

export const editAccuntSchema = Yup.object();

export const payInFullSchema = Yup.object();

export const schedulePaymentSchema = Yup.object().shape({
  allowSchedulePayment: Yup.boolean().required(),
  redirectLink: Yup.boolean().required(),
  link: Yup.string(),
  extensionDays: Yup.number().required(),
  maxStartDays: Yup.date()
    .required("Max start date cannot be empty.")
    .test("minDate", "Date cannot be in the past", (val) => {
      if (val) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return val >= today;
      }

      return true;
    }),
});

export const paymentPlanSchema = Yup.object({
  allowPaymentPlan: Yup.boolean().required(),
  redirectLink: Yup.boolean().required(),
  link: Yup.string(),
  allowedMonths: Yup.number().required(),
  // percentageFirstInstallment: Yup.number().required(),
  firstInstallmentDateAllowed: Yup.boolean().required(),
  firstInstallmentDaysAllowed: Yup.number().required(),
});

export const changeStatusSchema = Yup.object();

export const collectionsSchema = Yup.object();

export const profileSettingsSchema = Yup.object().shape({
  name: Yup.string().required(),
  legalName: Yup.string().required(),
  subdomain: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.number().required(),
});

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old Password is required."),
  newPassword: Yup.string().required("New Password is required."),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Must match new password.")
    .required("Comfirm New Password is required"),
});

export const reportDateFilterSchema = Yup.object().shape({
  startDate: Yup.date()
    .required("Start date cannot be empty.")
    .test(
      "greaterThanEndDate",
      "Start date cannot be greater than End date.",
      (val, context) => {
        const endDate = new Date(context.parent.endDate);
        endDate.setHours(0, 0, 0, 0);

        if (val) {
          return !(val > endDate);
        }
        return true;
      }
    ),
  endDate: Yup.date()
    .required("End date cannot be empty.")
    .test("maxDate", "Date cannot be in the future.", (val) => {
      if (val) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return !(val > today);
      }

      return true;
    })
    .test(
      "lessThanStartDate",
      "End date cannot be less than Start date.",
      (val, context) => {
        const startDate = new Date(context.parent.startDate);
        startDate.setHours(0, 0, 0, 0);

        if (val) {
          return !(val < startDate);
        }

        return true;
      }
    ),
});
