import PageHeader from "../../../components/borrowerComponents/PageHeader";
import CTabs, { Tab } from "../../../components/tabs/CTabs";
import PaymentReport from "./paymentReports";

type Props = {};

const Reports = (props: Props) => {
  const tabs: Tab[] = [
    {
      label: "Payment Report",
      content: <PaymentReport />,
    },
  ];
  return (
    <>
      <PageHeader
        title="Reports"
        desc="Analyze with confidence. View and manage detailed reports effortlessly, streamlining your data insights process."
      />
      <CTabs tabs={tabs} />
    </>
  );
};

export default Reports;
