import { accountConfigRequest } from "../models/request/accountConfigRequest";
import { accountRequest } from "../models/request/accountRequest";
import { accountsRequest } from "../models/request/accountsRequest";
import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";

export const getAccounts = async ({
  size,
  page,
  lender,
  filter,
  searchString,
}: accountsRequest) => {
  try {
    const path: string = `/lenders/${lender}/accounts/all?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res = await createRequest(reqObj);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAccount = async ({
  lender,
  accountId,
  borrowerId,
}: accountRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/${accountId}`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj);
    return res.data.body;
  } catch (error) {
    console.log(error);
  }
};

export const getAccountConfig = async ({
  lender,
  accountId,
  borrowerId,
  type,
}: accountConfigRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/${accountId}/configs?type=${type}`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const putAccountConfig = async ({
  lender,
  accountId,
  borrowerId,
  type,
  body,
}: accountConfigRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/${accountId}/configs?type=${type}`;
    const reqObj: ICreateRequest = {
      path,
      method: "put",
      data: body,
    };

    const res: any = await createRequest(reqObj);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getPlan = async ({
  lender,
  accountId,
  borrowerId,
  type,
}: accountConfigRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/${accountId}/${type}`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAccountActivity = async ({
  lender,
  accountId,
  borrowerId,
}: accountRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/${accountId}/account-timeline`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateAccount = async ({
  lender,
  accountId,
  borrowerId,
  body,
}: accountRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/${accountId}`;
    const reqObj: ICreateRequest = {
      path,
      method: "put",
      data: body,
    };

    const res: any = await createRequest(reqObj);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAccountsTrail = async (lender: string) => {
  try {
    const path: string = `/lenders/${lender}/accounts/accounts-trail`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj);

    return res;
  } catch (error) {
    console.log(error);
  }
};
