import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AllBorrowers from "./scenes/modules/borrowers/allBorrowers";
import Borrower from "./scenes/modules/borrowers/borrower";
import Login from "./scenes/login";
import RequiresAuth from "./components/commonComponents/RequiresAuth";
import Dashboard from "./scenes/modules/dashboard";
import AllAccounts from "./scenes/modules/accounts/allAccounts";
import Account from "./scenes/modules/accounts/account";
import Disputes from "./scenes/modules/disputes";
import Loading from "./components/commonComponents/Loading";
import { useSelector } from "react-redux";
import { isLoading } from "./data/store/slices/utilSlice";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ProfileSettings from "./scenes/modules/settings/profileSettings";
import Collections from "./scenes/modules/collections";
import { useEffect } from "react";
import Pusher from "pusher-js";
import notification from "./utils/toast";
import Reports from "./scenes/modules/reports";

function App() {
  const loading = useSelector(isLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const lenderId = localStorage.getItem("lenderId");

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY || "", {
    cluster: "ap2",
  });
  const channel = pusher.subscribe("account-trail-request");

  useEffect(() => {
    if (token && location.pathname.includes("/l/")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    channel.bind(`account-trail-${lenderId}`, (data: any) => {
      notification(
        "Your file will begin downloading shortly.",
        "info",
        10000,
        true
      );
      handleDownload(data);
    });
  }, []);

  const handleDownload = (filename: string) => {
    const link = document.createElement("a");
    link.href = filename;
    link.download = `trails-report.csv`;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    document.body.removeChild(link);
  };

  return (
    <>
      {loading && <Loading />}
      <div className="h-screen">
        <Routes>
          <Route path="/l/:lenderName" element={<Login />} />

          <Route element={<RequiresAuth />}>
            <Route path="/" element={<Navigate to={"/dashboard"} />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/borrowers" element={<AllBorrowers />} />
            <Route path="/borrower" element={<Borrower />} />
            <Route path="/accounts" element={<AllAccounts />} />
            <Route path="/account" element={<Account />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/profile-settings" element={<ProfileSettings />} />
            <Route path="/disputes" element={<Disputes />} />
            <Route path="/profile-settings" element={<ProfileSettings />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/payment-report" element={<Reports />} />
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
