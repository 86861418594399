import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";
const lenderName: any = localStorage.getItem("lenderName");

export const getPaymentReports = async ({
  size,
  page,
  startDate,
  endDate,
}: {
  size: number;
  page: number;
  startDate: string;
  endDate: string;
}) => {
  try {
    const path: string = `/lenders/${lenderName}/reports/payment-report?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const downloadPaymentReport = async ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  try {
    const path = `/lenders/${lenderName}/reports/download-payment-report?startDate=${startDate}&endDate=${endDate}`;
    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };
    const res = await createRequest(reqObj);

    return res;
  } catch (error) {
    console.error("There was an error with the download:", error);
  }
};
