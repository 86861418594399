import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/borrowerComponents/PageHeader";
import InputField from "../../../../components/formComponents/fieldComponents/InputField";
import CButton from "../../../../components/formComponents/CButton";
import CTable from "../../../../components/table/CTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { getAccounts, getAccountsTrail } from "../../../../data/api/accountAPI";
import { Account } from "../../../../data/models/types/Account";
import { createTableHeaders } from "../../../../utils/helperFunctions";
import { useDispatch } from "react-redux";
import notification from "../../../../utils/toast";
import { setSkeletonWithDelay } from "../../../../data/store/thunk/utilThunk";

interface IAllAccounts {}

const AllAccounts = (props: IAllAccounts) => {
  const lenderName: any = localStorage.getItem("lenderName");
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsCount, setAccountsCount] = useState<number>(0);
  const [headers, setHeaders] = useState<any>([]);
  const [tableFilter, setTableFilter] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");

  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const tableFilters = [
    "All",
    "In Collection",
    "Payment Plan",
    "Scheduled Payment",
    "Settled",
    "Disputed",
  ];

  useEffect(() => {
    getAllAccounts(10, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilter, searchString]);

  const getAllAccounts = async (size: number, page: number) => {
    try {
      dispatch(setSkeletonWithDelay(true));
      const res = await getAccounts({
        lender: lenderName,
        size: size,
        page: page,
        filter: tableFilter,
        searchString: searchString,
      });

      if (res?.status === 200) {
        const { body } = res.data;
        setAccounts(body?.accounts!);
        setAccountsCount(body?.total!);
        setHeaders(createTableHeaders(body?.headers!));
      }
      dispatch(setSkeletonWithDelay(false));
    } catch (error: any) {
      notification(error.message, "error");
      dispatch(setSkeletonWithDelay(false));
    }
  };

  const onFilterClick = (filterIndex: number) => {
    const filterProperty = tableFilters[filterIndex];
    switch (filterProperty) {
      case "All":
        setTableFilter("");
        break;
      case "In Collection":
        setTableFilter("in-collection");
        break;
      case "Payment Plan":
        setTableFilter("payment-plan");
        break;
      case "Scheduled Payment":
        setTableFilter("scheduled");
        break;
      case "Settled":
        setTableFilter("resolved");
        break;
      case "Disputed":
        setTableFilter("disputed");
        break;

      default:
        break;
    }
  };

  const onRowClick = (rowId?: number, row?: any) => {
    navigate("/account", {
      state: { borrowerid: row.borrowerPublicID, accountId: rowId },
    });
  };

  return (
    <>
      <PageHeader
        title="All Accounts"
        desc="Get insights into borrower accounts and statuses with just a click. Simplifying your account management experience."
      />
      <div className="px-10 py-8">
        <div className="flex flex-wrap justify-between items-end">
          <div className="flex items-end">
            <InputField
              className="grow lg:w-[560px]"
              placeholder="Search by reference id, status etc..."
              search={true}
              onSearchSubmit={setSearchString}
              name="search"
            />
            <CButton
              className="ml-3"
              text=""
              type="outlined"
              varient="icon-button"
              icon={<FilterListIcon />}
            />
          </div>
          {lenderName === "mashreqbk" && (
            <div>
              <CButton
                className="ml-3"
                text="Download Accounts Trails"
                type="contained"
                onClick={async () => {
                  const res = await getAccountsTrail(lenderName);
                  if (res.status === 200) {
                    notification(
                      "File creation initiated. Your file will begin downloading shortly. This could take several minutes.",
                      "success",
                      5000,
                      true
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="mt-8">
          <CTable
            headCells={headers}
            rows={accounts}
            tabs={true}
            minWidth={750}
            tableFilters={tableFilters}
            onRowClick={onRowClick}
            refreshDataAPI={getAllAccounts}
            totalRows={accountsCount}
            filterAction={onFilterClick}
            rowIdentifier="accountPublicId"
          />
        </div>
      </div>
    </>
  );
};

export default AllAccounts;
